import * as React from "react";

import { cn } from "~/lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  state?: "success" | "error";
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, state, ...props }, ref) => {
  if (props.readOnly) {
    props.tabIndex = -1;
  }
  return (
    <input
      type={type}
      className={cn(
        "flex h-10 w-full rounded-md text-base border border-input bg-background px-3 py-2 ring-offset-background file:border-0 file:bg-transparent file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&[readonly]]:border-0 [&[readonly]]:bg-white/20 [&[readonly]]:ring-0 [&[readonly]]:ring-offset-0",
        {
          "border-red-500": state === "error",
          "border-green-500": state === "success",
        },
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

export { Input };
